import React, { useEffect, useState } from "react";
import { createDeck, shuffleDeck } from "./Deck";
import { Table } from "./Table";
import { OpenMarker, TakenMarker } from "./Open";
import { styled } from "styled-components";
import { PotMarker, PotMarkerContainer } from "./Pot";
import { Button } from "react-bootstrap";
// import { addDoc } from "firebase/firestore";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
`;

export default function EmptyGame(props) {
  return (
    <>
      <Container>
        <TableContainer>
          <Table>
            <PotMarkerContainer>
              <PotMarker>
                <p className="white">Pot: $0.00</p>
              </PotMarker>
            </PotMarkerContainer>
          </Table>
          <Button style={{ backgroundColor: "#003a62", border: "none" }}>
            Deal Cards
          </Button>
        </TableContainer>
        {!props.players || !props.players[1] || !props.players[1].playerName ? (
          <OpenMarker style={{ left: "30%", top: "75%" }}>Open</OpenMarker>
        ) : (
          <TakenMarker style={{ left: "30%", top: "75%" }}>
            <p className="mb-0">
              <b>{props.players[1].playerName}</b>
              <br />
              {props.players[1].buyChipsNum}c
            </p>
          </TakenMarker>
        )}
        {!props.players || !props.players[2] || !props.players[2].playerName ? (
          <OpenMarker style={{ left: "12%", top: "58%" }}>Open</OpenMarker>
        ) : (
          <TakenMarker style={{ left: "12%", top: "58%" }}>
            <p className="mb-0">
              <b>{props.players[2].playerName}</b>
              <br />
              {props.players[2].buyChipsNum}c
            </p>
          </TakenMarker>
        )}
        {!props.players || !props.players[3] || !props.players[3].playerName ? (
          <OpenMarker style={{ left: "12%", top: "25%" }}>Open</OpenMarker>
        ) : (
          <TakenMarker style={{ left: "12%", top: "25%" }}>
            <p className="mb-0">
              <b>{props.players[3].playerName}</b>
              <br />
              {props.players[3].buyChipsNum}c
            </p>
          </TakenMarker>
        )}
        {!props.players || !props.players[4] || !props.players[4].playerName ? (
          <OpenMarker style={{ left: "30%", top: "12%" }}>Open</OpenMarker>
        ) : (
          <TakenMarker style={{ left: "30%", top: "12%" }}>
            <p className="mb-0">
              <b>{props.players[4].playerName}</b>
              <br />
              {props.players[4].buyChipsNum}c
            </p>
          </TakenMarker>
        )}
        {!props.players || !props.players[5] || !props.players[5].playerName ? (
          <OpenMarker style={{ left: "70%", top: "12%" }}>Open</OpenMarker>
        ) : (
          <TakenMarker style={{ left: "70%", top: "12%" }}>
            <p className="mb-0">
              <b>{props.players[5].playerName}</b>
              <br />
              {props.players[5].buyChipsNum}c
            </p>
          </TakenMarker>
        )}
        {!props.players || !props.players[6] || !props.players[6].playerName ? (
          <OpenMarker style={{ left: "89%", top: "25%" }}>Open</OpenMarker>
        ) : (
          <TakenMarker style={{ left: "89%", top: "25%" }}>
            <p className="mb-0">
              <b>{props.players[6].playerName}</b>
              <br />
              {props.players[6].buyChipsNum}c
            </p>
          </TakenMarker>
        )}
        {!props.players || !props.players[7] || !props.players[7].playerName ? (
          <OpenMarker style={{ left: "89%", top: "58%" }}>Open</OpenMarker>
        ) : (
          <TakenMarker style={{ left: "89%", top: "58%" }}>
            <p className="mb-0">
              <b>{props.players[7].playerName}</b>
              <br />
              {props.players[7].buyChipsNum}c
            </p>
          </TakenMarker>
        )}
        {!props.players || !props.players[0] || !props.players[0].playerName ? (
          <OpenMarker style={{ left: "70%", top: "75%" }}>Open</OpenMarker>
        ) : (
          <TakenMarker style={{ left: "70%", top: "75%" }}>
            <p className="mb-0">
              <b>{props.players[0].playerName}</b>
              <br />
              {props.players[0].buyChipsNum}c
            </p>
          </TakenMarker>
        )}
      </Container>
    </>
  );
}
