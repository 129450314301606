import { useState } from "react";
import { Button, InputGroup, Modal } from "react-bootstrap";

import { collection, addDoc } from "firebase/firestore";

import { BlindInput, ModalInput } from "../components/ModalInputs";
import { db } from "../firebase.config";

import {
  BuyInButton,
  BuyInContainer,
  BuyInInput,
} from "../components/BuyInButton";
import PokerGame from "../components/Game";
import EmptyGame from "../components/EmptyGame";

function GamePage() {
  const [show, setShow] = useState(true);
  const [showInputModal, setInputModal] = useState(false);
  const [showGameModal, setGameModal] = useState(false);

  const [name, setName] = useState("");
  const [smallBlind, setSmallBlind] = useState("");
  const [bigBlind, setBigBlind] = useState("");

  const [gameCode, setGameCode] = useState("");

  const openInputModal = () => {
    setShow(false);
    setInputModal(true);
  };

  const openGameModal = () => {
    setShow(false);
    setGameModal(true);
  };

  const createGame = async (e) => {
    e.preventDefault();

    try {
      const gameID = await addDoc(collection(db, "games"), {
        smallBlind: smallBlind,
        bigBlind: bigBlind,
      });

      const playerID = await addDoc(collection(db, "players"), {
        playerName: name,
        gameCode: gameID.id,
      });

      localStorage.setItem("playerID", playerID.id);
      setGameModal(false);
      window.location.href = `/game/${gameID.id}`;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const goToGame = async (e) => {
    e.preventDefault();

    try {
      const playerID = await addDoc(collection(db, "players"), {
        playerName: name,
        gameCode: gameCode,
      });
      localStorage.setItem("playerID", playerID.id);
      setInputModal(false);
      window.location.href = `/game/${gameCode}`;
    } catch (e) {
      console.error("Error adding doc", e);
    }
  };

  return (
    <>
      {/* intro modal */}
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        style={{ top: "30%" }}
      >
        <Modal.Header>
          <Modal.Title>Welcome to Zoe's Poker Room ♠️</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Here's to many more years of poker ahead of us. Merry Christmas, Zoe.
          <br /> <br />- M
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={openInputModal}>
            Input Code
          </Button>
          <Button variant="primary" onClick={openGameModal}>
            Create a Game
          </Button>
        </Modal.Footer>
      </Modal>

      {/* code modal */}
      <Modal
        show={showInputModal}
        backdrop="static"
        keyboard={false}
        style={{ top: "30%" }}
      >
        <Modal.Header>
          <Modal.Title>Input a Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-10">
            <b>Your Name</b>
          </p>{" "}
          <ModalInput onChange={(e) => setName(e.target.value)} />
          <p className="mt-20 mb-10">
            <b>Code</b>
          </p>{" "}
          <ModalInput onChange={(e) => setGameCode(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={goToGame}>
            Enter Game
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create game modal */}
      <Modal
        show={showGameModal}
        backdrop="static"
        keyboard={false}
        style={{ top: "30%" }}
      >
        <Modal.Header>
          <Modal.Title>Create a Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-10">
            <b>Your Name</b>
          </p>{" "}
          <ModalInput onChange={(e) => setName(e.target.value)} />
          <p className="mt-20 mb-10">
            <b>Blinds (Chips)</b>
          </p>{" "}
          <InputGroup>
            <BlindInput
              placeholder="SB"
              onChange={(e) => setSmallBlind(e.target.value)}
            />{" "}
            <p className="mr-10 ml-10">
              <b>/</b>
            </p>{" "}
            <BlindInput
              placeholder="BB"
              onChange={(e) => setBigBlind(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={createGame}>
            Get Started
          </Button>
        </Modal.Footer>
      </Modal>

      <h5 className="mt-20 ml-20">Zoe's Poker Game ♠️ </h5>
      <h6 className="mt-10 ml-20">Blinds: N/A</h6>
      <EmptyGame />
      <BuyInContainer>
        <BuyInInput type="number" placeholder="$10.00" />
        <BuyInButton>Buy In</BuyInButton>
      </BuyInContainer>
    </>
  );
}
export default GamePage;
