// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD0LqmWcCQRGEWvtG17iO4GXNdcrrfF0AI",
  authDomain: "poker-club-74eca.firebaseapp.com",
  projectId: "poker-club-74eca",
  storageBucket: "poker-club-74eca.appspot.com",
  messagingSenderId: "1022098429826",
  appId: "1:1022098429826:web:5691aeaa2cd27b20ea619b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
