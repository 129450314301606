import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import {
  BuyInButton,
  BuyInContainer,
  BuyInInput,
} from "../components/BuyInButton";
import PokerGame from "../components/Game";
import { db } from "../firebase.config";

function CreatedGame() {
  const { id } = useParams();

  const [smallBlind, setSmallBlind] = useState("");
  const [bigBlind, setBigBlind] = useState("");

  const [playerID, setPlayerID] = useState("");
  const [buyChipsNum, setBuyChipsNum] = useState(0);

  const [players, setPlayers] = useState([]); // Add this line to store players data

  const buyChips = async (e) => {
    e.preventDefault();
    const playerRef = doc(db, "players", playerID);

    try {
      await updateDoc(playerRef, {
        buyChipsNum: buyChipsNum,
      });
      window.location.reload();
    } catch (e) {
      console.error("Error adding doc", e);
    }
  };

  const getGame = async () => {
    if (id) {
      const docRef = await doc(db, "games", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setSmallBlind(docSnap.data().smallBlind);
        setBigBlind(docSnap.data().bigBlind);
      } else {
        console.log("No such document!");
      }
    }
  };

  const getPlayers = async () => {
    if (id) {
      const docsRef = await collection(db, "players");
      const q = await query(docsRef, where("gameCode", "==", id));
      const querySnapshot = await getDocs(q);
      const playersData = [];
      querySnapshot.forEach((doc) => {
        playersData.push(doc.data());
      });
      console.log("players", playersData);
      setPlayers(playersData); // Update the state with the players data
    }
  };

  useEffect(() => {
    setPlayerID(localStorage.getItem("playerID"));
    getGame();
    getPlayers();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <h5 className="mt-20 ml-20">Zoe's Poker Game ♠️ </h5>
      <h6 className="mt-10 ml-20">
        Blinds: {smallBlind} / {bigBlind}
      </h6>

      <PokerGame players={players} playerID={playerID} />
      <BuyInContainer>
        <BuyInInput
          type="number"
          placeholder="100"
          onChange={(e) => setBuyChipsNum(e.target.value)}
        />
        <BuyInButton onClick={buyChips}>Buy In (Chips)</BuyInButton>
      </BuyInContainer>
      <h6
        className="mt-10 ml-20"
        style={{ position: "absolute", bottom: "60px", left: "10px" }}
      >
        Game Code: {id}
      </h6>
    </>
  );
}
export default CreatedGame;
