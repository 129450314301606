// Deck.js
const suits = ["Hearts", "Diamonds", "Clubs", "Spades"];
const values = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "J",
  "Q",
  "K",
  "A",
];

export function createDeck() {
  let deck = [];
  for (let suit of suits) {
    for (let value of values) {
      deck.push({
        suit,
        value,
      }); // Adjust the image path
    }
  }
  return deck;
}

// Add this to Deck.js
export function shuffleDeck(deck) {
  for (let i = deck.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [deck[i], deck[j]] = [deck[j], deck[i]];
  }
  return deck;
}
