import "./App.css";

import { Route, Routes } from "react-router-dom";
import GamePage from "./pages/Game";
import CreatedGame from "./pages/CreatedGame";

function App() {
  return (
    <Routes>
      <Route path="/" element={<GamePage />} />
      <Route path="/game/:id" element={<CreatedGame />} />
    </Routes>
  );
}

export default App;
